<template>
  <div class="pager">
    <div class="nav">
      <van-nav-bar
        :title="$t('loan.name')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>

    <div class="box">
      <div class="box_header">
        <div class="desc">
          <span>{{ $t("loan.remainingBalance") }}</span>
        </div>
        <div class="money">
          <span>{{ info.orderMoney || 0.0 }}</span>
          <span>USDT</span>
        </div>

        <div class="order" @click="onSubscribeList">
          <span>{{ $t("loan.loanList") }}</span>
        </div>
      </div>
      <!-- :finished-text="items.length == 0 ? '' : $t('common.nomore')" -->
      <van-list
        v-model="loading"
        :finished="finished"
        :loading-text="$t('jia-zai-zhong')"
        @load="onLoad"
      >
        <div class="box_list">
          <div
            class="cell"
            v-for="(item, key) in items"
            :key="key"
            @click="onPurchase(item)"
          >
            <div class="bottom">
              <div>
                <span>{{ $t("financial.list.items.0") }}</span>
                <span>{{ item.min }}~{{ item.max }}</span>
              </div>
              <div>
                <span>{{ $t("loan.interestRate") }}</span>
                <span>{{ item.day_rate }}%</span>
              </div>
              <div>
                <span>{{ $t("financial.list.items.2") }}</span>
                <span>{{ item.days }}{{ $t("financial.list.sufix") }}</span>
              </div>
              <div class="top">
                <van-button type="primary" size="small">
                  {{ $t("loan.goForLoan") }}
                </van-button>
              </div>
            </div>
          </div>
        </div>
        <!-- :description="$t('common.nomore')" -->
        <van-empty v-if="items.length == 0" />
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      items: [],
      page: 0,
      info: {
        orderMoney: "",
      },
    };
  },
  created() {
    this.onLoadData();
  },
  methods: {
    async onLoadData() {
    const { data } = await this.$http.get("/home/home/index");
      if (data) {
        if (data.code === 200) {
          this.info.orderMoney = data.data.user.loan;
        }
      }
    },
    onLoad() {
      this.page++;

      this.$http
        .post("/home/user/loanSet")
        .then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            // if (res.data.data.length == 0) {
            this.finished = true;
            // }
            // if (this.page == 1) {
            this.items = res.data.data;
            // } else {
            //     this.items.push(...res.data.data);
            // }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.finished = true;
        });
    },
    onPurchase(item) {
      this.$router.push({ path: "/person/Apply/" + JSON.stringify(item) });
    },
    onSubscribeList() {
      this.$router.push({ path: "/person/loanList" });
    },
  },
};
</script>

<style scoped lang="less">
.pager {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #e3e6ea;
  overflow-y: scroll;
}

.nav {
  position: fixed;
  width: 100vw;
  font-size: 1.12rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.box {
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-top: 46px;

  .box_header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    color: #fff;
    background: #f0b82d;
    padding: 60px 0 60px 0;

    .desc {
      margin-left: 18px;
      font-size: 18px;
      font-weight: 400;
    }

    .money {
      margin-left: 18px;
      font-size: 40px;
      font-weight: 500;

      span:last-child {
        margin-left: 5px;
        font-size: 20px;
      }
    }

    .box_items {
      padding: 12px 0;
      width: 100vw;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      background: rgba(255, 255, 255, 0.2);

      div {
        div:first-child {
          font-size: 16px;
          font-weight: 600;
        }

        div:last-child {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .order {
      position: absolute;
      right: 0px;
      top: 85px;
      color: #363636;
      font-size: 15px;
      font-weight: 400;
      background: rgba(255, 255, 255, 0.82);
      padding: 5px 20px;
      border-radius: 16px 0 0 16px;
    }
  }

  .box_list {
    width: 100vw;
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    .cell {
      padding: 18px 0;

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        img {
          width: 28px;
          height: 28px;
        }

        .name {
          color: #333;
          font-size: 16px;
          font-weight: 500;
          flex: 1;
          margin: 0 14px;
          text-align: left;
        }

        /deep/.van-button--primary {
          border-radius: 5px;
          background: #f0b82d;
          border: none;
        }
      }

      .bottom {
        margin-top: 22px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span:first-child {
            color: #777;
            font-size: 12px;
            font-weight: 400;
          }

          span:last-child {
            margin-top: 6px;
            color: #363636;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
